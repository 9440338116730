import { Text, TextProps } from "@mantine/core";

const MetaText = ({
  children,
  ...props
}: { children: React.ReactNode } & TextProps) => {
  return (
    <Text
      fz={{ base: 14, sm: 18 }}
      lh="1.55"
      ff="Roboto"
      c="customBlack.6"
      fw={400}
      {...props}
    >
      {children}
    </Text>
  );
};
export default MetaText;
