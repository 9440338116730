import {
  components,
  CSSObjectWithLabel,
  GroupBase,
  MultiValue,
  PropsValue,
  SingleValue,
  StylesConfig,
  ControlProps,
  PlaceholderProps,
  IndicatorSeparatorProps,
  MultiValueProps,
  MenuListProps,
  OptionProps,
  NoticeProps,
  SingleValueProps,
  InputProps,
  SelectComponentsConfig,
  InputActionMeta,
} from "react-select";
import AsyncSelect from "react-select/async";
import { CustomSelectOption } from "@/types/common";
import { IconX } from "@tabler/icons-react";
import { Noop } from "react-hook-form";
import { MenuProps } from "react-select";
import { Ref, forwardRef, useEffect } from "react";
import Select from "react-select/base";
import { useScreenBreakpoints } from "@/contexts/ScreenBreakpointsProvider";
// import { PortalStyleArgs } from "fix"
//TODO : Fix PortalStyleArgs type import

const getStyles = (
  customStyles:
    | StylesConfig<CustomSelectOption, boolean, GroupBase<CustomSelectOption>>
    | undefined,
  isMobile: boolean | undefined
) => {
  return {
    control: (
      baseStyles: CSSObjectWithLabel,
      props: ControlProps<
        CustomSelectOption,
        boolean,
        GroupBase<CustomSelectOption>
      >
    ) => {
      return {
        ...baseStyles,
        fontSize: isMobile ? "16x" : "18px",
        fontWeight: "400",
        fontFamily: "Inter",
        minHeight: "40px",
        borderRadius: "100px",
        borderColor: "black",
        padding: isMobile ? "1px 10px" : "10px 15px",
        borderWidth: "0.5px",
        backgroundColor: "white",
        // paddingBlock: isMobile ? "0px" : "15px",
        ":hover": {
          ...baseStyles[":active"],
          border: "0.5px solid rgb(0, 0, 0)",
          boxShadow: "0 0 0 0 #000",
        },
        ":focus": {
          border: "0.5px solid rgb(0, 0, 0)",
          boxShadow: "0 0 0 0 #000",
        },
        ":not(:hover)": {
          border: "0.5px solid rgb(0, 0, 0)",
          boxShadow: "0 0 0 0 #000",
        },
        ...customStyles?.control?.(baseStyles, props),
      };
    },
    input: (
      baseStyles: CSSObjectWithLabel,
      props: InputProps<
        CustomSelectOption,
        boolean,
        GroupBase<CustomSelectOption>
      >
    ) => ({
      ...baseStyles,
      borderSize: "0.5px",
      ...customStyles?.input?.(baseStyles, props),
    }),
    placeholder: (
      baseStyles: CSSObjectWithLabel,
      props: PlaceholderProps<
        CustomSelectOption,
        boolean,
        GroupBase<CustomSelectOption>
      >
    ) => ({
      ...baseStyles,
      fontSize: isMobile ? "13px" : "14px",
      color: "var(--mantine-color-gray-5)",
      ...customStyles?.placeholder?.(baseStyles, props),
    }),
    // menuPortal: (baseStyles: CSSObjectWithLabel, props: PortalStyleArgs) => {
    menuPortal: (baseStyles: CSSObjectWithLabel, props: any) => {
      return {
        ...baseStyles,
        fontSize: "16px",
        backgroundColor: "white",
        color: "black",
        zIndex: 9999,
        ...customStyles?.menuPortal?.(baseStyles, props),
      };
    },
    indicatorSeparator: (
      baseStyles: CSSObjectWithLabel,
      props: IndicatorSeparatorProps<
        CustomSelectOption,
        boolean,
        GroupBase<CustomSelectOption>
      >
    ) => ({
      display: "none",
      ...customStyles?.indicatorSeparator?.(baseStyles, props),
    }),
    option: (
      baseStyles: CSSObjectWithLabel,
      props: OptionProps<
        CustomSelectOption,
        boolean,
        GroupBase<CustomSelectOption>
      >
    ) => {
      return {
        ...baseStyles,
        ":hover": {
          ...baseStyles[":active"],
          backgroundColor: "#e6e6e6",
          cursor: "pointer",
        },
        padding: "10px",
        backgroundColor: "white",
        color: "black",
        fontSize: "16px",
        cursor: "pointer",
        textTransform: "capitalize",
        ":active": {
          ...baseStyles[":active"],
          backgroundColor: !props.isDisabled
            ? props.isSelected
              ? "white"
              : "white"
            : undefined,
        },
        ...customStyles?.option?.(baseStyles, props),
      } as CSSObjectWithLabel; // TODO : Fix type error as textTransform: "capitalize", is not currently accepted
    },
    noOptionsMessage: (
      baseStyles: CSSObjectWithLabel,
      props: NoticeProps<
        CustomSelectOption,
        boolean,
        GroupBase<CustomSelectOption>
      >
    ) => ({
      padding: "10px",
      backgroundColor: "white",
      color: "black",
      ...customStyles?.noOptionsMessage?.(baseStyles, props),
    }),
    menu: (
      baseStyles: CSSObjectWithLabel,
      props: MenuProps<
        CustomSelectOption,
        boolean,
        GroupBase<CustomSelectOption>
      >
    ) => ({
      ...baseStyles,
      ...customStyles?.menu?.(baseStyles, props),
    }),
    menuList: (
      baseStyles: CSSObjectWithLabel,
      props: MenuListProps<
        CustomSelectOption,
        boolean,
        GroupBase<CustomSelectOption>
      >
    ) => ({
      backgroundColor: "white",
      borderColor: "white",
      zIndex: 1000,
      maxHeight: "300px !important",
      overflow: "auto",
      ...customStyles?.menuList?.(baseStyles, props),
    }),
    multiValue: (
      baseStyles: CSSObjectWithLabel,
      props: MultiValueProps<
        CustomSelectOption,
        boolean,
        GroupBase<CustomSelectOption>
      >
    ) => ({
      ...baseStyles,
      backgroundColor: "white",
      paddingInline: "4px",
      borderRadius: "10px",
      borderColor: "white",
      fontWeight: 500,
      ...customStyles?.multiValue?.(baseStyles, props),
    }),
    singleValue: (
      baseStyles: CSSObjectWithLabel,
      props: SingleValueProps<
        CustomSelectOption,
        boolean,
        GroupBase<CustomSelectOption>
      >
    ) => {
      return {
        ...baseStyles,
        textTransform: "capitalize",
        color: "black",
        fontWeight: 400,
        ...customStyles?.singleValue?.(baseStyles, props),
      } as CSSObjectWithLabel; // TODO : Fix type error as textTransform: "capitalize", is not currently accepted),
    },
    multiValueLabel: (
      baseStyles: CSSObjectWithLabel,
      props: MultiValueProps<
        CustomSelectOption,
        boolean,
        GroupBase<CustomSelectOption>
      >
    ) => {
      return {
        ...baseStyles,
        color: "white",
        paddingRight: "16px",
        textTransform: "capitalize",
        ...customStyles?.multiValueLabel?.(baseStyles, props),
      } as CSSObjectWithLabel; // TODO : Fix type error as textTransform: "capitalize", is not currently accepted),
    },
    multiValueRemove: (
      baseStyles: CSSObjectWithLabel,
      props: MultiValueProps<
        CustomSelectOption,
        boolean,
        GroupBase<CustomSelectOption>
      >
    ) => {
      return {
        ...baseStyles,
        ":hover": {
          ...baseStyles[":active"],
          backgroundColor: "black",
          borderRadius: "20px",
          cursor: "pointer",
        },
        color: "white",
        ...customStyles?.multiValueRemove?.(baseStyles, props),
      };
    },
  };
};

const AsyncSearchSelect = forwardRef(
  (
    {
      name,
      isDisabled,
      value,
      onChange,
      onMultipleChange,
      placeholder,
      cacheOptions,
      getOptions,
      instanceId,
      showDropdownOnModal,
      dropDownIcon,
      customStyles,
      isMulti,
      onBlur,
      controlShouldRenderValue = true,
      backspaceRemovesValue = true,
      isClearable = true,
      hideSelectedOptions = true,
      onInputChange,
      customComponents,
      menuIsOpen,
      autoFocus,
    }: {
      name?: string;
      isDisabled?: boolean;
      value: PropsValue<CustomSelectOption>;
      onChange: (value: CustomSelectOption | null) => void;
      onMultipleChange?: (value: CustomSelectOption[]) => void;
      onBlur?: Noop;
      placeholder?: string;
      cacheOptions?: any;
      getOptions: (v: string) => Promise<CustomSelectOption[]>;
      instanceId: string;
      showDropdownOnModal?: boolean;
      dropDownIcon?: React.ReactNode;
      customStyles?: StylesConfig<
        CustomSelectOption,
        boolean,
        GroupBase<CustomSelectOption>
      >;
      isMulti?: boolean;
      controlShouldRenderValue?: boolean;
      backspaceRemovesValue?: boolean;
      isClearable?: boolean;
      hideSelectedOptions?: boolean;
      onInputChange?:
        | ((newValue: string, actionMeta: InputActionMeta) => void)
        | undefined;
      customComponents?: SelectComponentsConfig<
        CustomSelectOption,
        boolean,
        GroupBase<CustomSelectOption>
      >;
      menuIsOpen?: boolean;
      autoFocus?: boolean;
    },
    ref: Ref<
      Select<CustomSelectOption, boolean, GroupBase<CustomSelectOption>>
    > | null
  ) => {
    const DropDownIndicator = (props: any) => {
      if (dropDownIcon === undefined) {
        return null;
      }
      return (
        <components.DropdownIndicator {...props}>
          {dropDownIcon}
        </components.DropdownIndicator>
      );
    };

    const ClearIndicator = (props: any) => {
      if (isMulti) {
        return null;
      }
      return (
        <components.ClearIndicator {...props}>
          <IconX size="14px" color="black" style={{ cursor: "pointer" }} />
        </components.ClearIndicator>
      );
    };

    const onSelect = (
      value:
        | MultiValue<CustomSelectOption>
        | SingleValue<CustomSelectOption>
        | null
    ) => {
      if (value === null) {
        onChange(null);
        return;
      }
      if (isMulti && onMultipleChange) {
        onMultipleChange([...(value as MultiValue<CustomSelectOption>)]);
        return;
      }
      onChange(value as SingleValue<CustomSelectOption>);

      return;
    };
    const { isMobile, isTablet } = useScreenBreakpoints();
    useEffect(() => {
      // If autoFocus is not true, we don't need to do anything
      if (!autoFocus) {
        return;
      }

      // A timeout is used here to ensure that the scroll operation happens after the component has fully rendered
      // This is necessary because there's a known issue where scrolling doesn't work correctly if it's done immediately after render
      // The delay is set to 1ms, which is enough to let the component render but short enough that the user won't notice a delay
      const timeoutId = setTimeout(() => {
        const selectElement = document.getElementById(instanceId);
        // Find the input element within the select element
        const inputElement = selectElement?.querySelector("input");
        // Focus the input element
        inputElement?.focus();
      }, 1); // 1ms delay

      // Cleanup function to clear the timeout if the component unmounts before the timeout finishes
      return () => clearTimeout(timeoutId);
    }, [instanceId, autoFocus]);

    return (
      <AsyncSelect
        ref={ref}
        aria-label={`searchField-${name}`}
        instanceId={instanceId}
        id={instanceId}
        name={name}
        isLoading={false}
        onChange={onSelect}
        cacheOptions={cacheOptions}
        defaultOptions
        isClearable={isClearable}
        loadOptions={getOptions}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder ?? "Select a value"}
        isDisabled={isDisabled}
        styles={getStyles(customStyles, isMobile || isTablet)}
        isMulti={isMulti}
        onInputChange={onInputChange}
        components={{
          DropdownIndicator: DropDownIndicator,
          ClearIndicator: ClearIndicator,
          ...(customComponents ?? {}),
        }}
        hideSelectedOptions={hideSelectedOptions}
        menuPortalTarget={showDropdownOnModal === true ? document.body : null}
        controlShouldRenderValue={controlShouldRenderValue}
        backspaceRemovesValue={backspaceRemovesValue}
        menuIsOpen={menuIsOpen}
      />
    );
  }
);
AsyncSearchSelect.displayName = "AsyncSearchSelect";
export default AsyncSearchSelect;
