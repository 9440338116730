import { UserRoleEnum } from "contract/enum";
import {
  GetCommentsResponse,
  GetKeyMomentsResponse,
  TimelineTask,
} from "contract/timeline/types";
import { z } from "zod";
import { CustomSelectOption } from "@/types/common";

export enum TimelinePageViewEnum {
  listView = "List View",
  gridView = "Grid View",
}
export const uncategorizedLabel = "Uncategorized";

export type GetCommentsResponseType = z.infer<typeof GetCommentsResponse>;

export type GetKeyMomentsResponseType = z.infer<typeof GetKeyMomentsResponse>;
export type GetTimelineTaskType = z.infer<typeof TimelineTask>;

export interface SearchUser {
  id: string;
  name: string;
  email: string;
  userImageURL: string | null;
  role: UserRoleEnum;
}

export interface CustomSelectOptionWithUser extends CustomSelectOption {
  user: SearchUser;
}

export interface HeaderSectionSearchInterface {
  searchText: string;
}
