import { infer as ZodInfer } from "zod";
import {
  GetVendorReponse,
  UpdateVendorBody,
  GetAllVendorResponse,
  VendorCalendarResponse,
  GetSubVendorResponse,
  BulkUploadSuccessSchema,
  FileUploadErrorResponseSchema,
} from "contract/vendor/types";
import { CustomSelectOption } from "@/types/common";
import { RequestStatus } from "contract/enum";
export enum ManageListingActionTypes {
  edit = "edit",
  view = "view",
}
export const ManageListingFormId = "ManageListingFormId";

export type GetVendorReponseType = ZodInfer<typeof GetVendorReponse>;
export type GetSubVendorResponse = ZodInfer<typeof GetSubVendorResponse>;
export type UpdateVendorBodyType = ZodInfer<typeof UpdateVendorBody>;
export type GetAllVendorResponseType = ZodInfer<typeof GetAllVendorResponse>;
export type VendorCalendarResponseType = ZodInfer<
  typeof VendorCalendarResponse
>;

export type BulkUploadSuccessSchemaType = ZodInfer<
  typeof BulkUploadSuccessSchema
>;
export type FileUploadErrorResponseSchemaType = ZodInfer<
  typeof FileUploadErrorResponseSchema
>;
export type ModifiedUpdateVendorBodyType = Omit<
  UpdateVendorBodyType,
  "services" | "categories" | "location" | "vendors"
> & {
  services: {
    id?: string;
    guestCapacity: CustomSelectOption | null;
    pricingDetails: string;
    price: number | null;
  }[];
  categories: CustomSelectOption[] | null;
  location: CustomSelectOption | null;
  vendors: {
    id?: string;
    email: string;
    vendorName: string;
    aboutCompanyAndMakeItEasierForPlanners: string;
    category: CustomSelectOption[] | null;
    status: RequestStatus | null;
  }[];
};

export enum VendorPricingOptions {
  CustomValues = "customValues",
  ContactForPrice = "contactForPrice",
}

export type VendorDirectoryFiltersType = {
  pageSize: number;
  location: CustomSelectOption[] | null;
  guestCapacity: CustomSelectOption[] | null;
  priceRange: CustomSelectOption | null;
  category: CustomSelectOption[] | null;
  availableDate: Date | null;
  searchText: string;
};
export enum VendorAvailabilityDateEnum {
  today = "today",
  tomorrow = "tomorrow",
  custom = "custom",
}
